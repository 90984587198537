import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
`;

const ErrorTitle = styled.h1`
  color: ${props => props.theme.colors.error};
  margin-bottom: 16px;
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.text};
  margin-bottom: 24px;
`;

const ReloadButton = styled.button`
  padding: 8px 16px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

interface ErrorFallbackProps {
  error: Error;
  resetError?: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetError }) => (
  <ErrorContainer>
    <ErrorTitle>Something went wrong</ErrorTitle>
    <ErrorMessage>
      {error.message || "An unexpected error occurred. Please try again later."}
    </ErrorMessage>
    {resetError && <ReloadButton onClick={resetError}>Try again</ReloadButton>}
  </ErrorContainer>
);

export default ErrorFallback;
