// import main style dependency file
import "antd/dist/antd.min.css";
import "./index.css";

import * as React from "react";
import { initializeSentry, SentryErrorBoundary } from "./services/sentry";
import ErrorFallback from "./components/ErrorFallback";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Authenticator from "./Authenticator";
import GlobalStyle from "./globalStyles";
import theme from "./theme";

// Initialize Sentry as early as possible
initializeSentry(process.env.REACT_APP_SENTRY_DSN || "");

const App = () => (
  <SentryErrorBoundary fallback={props => <ErrorFallback {...props} />}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Authenticator />
        <GlobalStyle />
      </BrowserRouter>
    </ThemeProvider>
  </SentryErrorBoundary>
);

const rootEl = document.getElementById("root");

ReactDOM.render(<App />, rootEl);

if (module["hot"]) {
  module["hot"].accept(App, () => {
    // const NextApp = require("./App").default;
    ReactDOM.render(<App />, rootEl);
  });
}
