import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_CONFIG } from "../../common/config/sentry";

export function initializeSentry(dsn: string): void {
  if (!dsn) {
    console.warn("Sentry DSN not provided, error tracking disabled");
    return;
  }

  try {
    Sentry.init({
      dsn,
      ...SENTRY_CONFIG,
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false
        })
      ],
      // Enable source maps
      attachStacktrace: true,
      // Set release version
      // release: process.env.npm_package_version,
      beforeSend(event) {
        // Allow events in development for testing
        return event;
      },
      tracesSampleRate: 1.0,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    });
  } catch (error) {
    console.error("Failed to initialize Sentry:", error);
  }
}

// Custom error boundary component
export const SentryErrorBoundary = Sentry.ErrorBoundary;
